import "./Tag.scss";

function Tag ({
    label,
    }) 
    {
    return (
        <div className="tag">
            <p className="caption-2 white">{label}</p>
        </div>
    );
}

export default Tag;