import "./VictoriaIvankovich.scss";
import Footer from "../../components/Footer/Footer";

function VictoriaIvankovich() {
    return ( 
        <div className="page">
            <a href="/">Назад</a>

            <div className="about-case">
                <h3>Victoria Ivankovich</h3>
                    <div className="description">
                        <p className="black-50">Бренд женского нижнего белья с собственным дизайном и производством.</p>
                        <div className="expertise gap-flex">
                            <h5 className="black-50">Экспертиза</h5>
                            <div className="caption-3">Веб-дизайн</div>
                            <div className="caption-3">Опыт взаимодействия</div>
                            <div className="caption-3">UI-kit</div>
                        </div>
                        <div className="year-project gap-flex">
                            <h5 className="black-50">Год проекта</h5>
                            <div className="caption-3">апрель — май, 2021</div>
                        </div>
                        <div className="platform gap-flex">
                            <h5 className="black-50">Платформа</h5>
                            <div className="caption-3">Веб</div>
                        </div>
                    </div>
            </div>

            <div className="video-case">
                <video autoPlay muted video loop playsInline webkit-playinginline >
                    <source src="../video/vi.webm" type="video/webm"/>
                </video>
            </div>

            <div className="container-title">
                <h4>Задача</h4>
                <p className="black-50 mb-16">Проектирование интерфейса и пользовательского опыта для удобной навигации по каталогу товаров, фильтрации по категориям, размеру и цвету. Сделать адаптивность и оптимизация для&nbsp;различных устройств, чтобы обеспечить удобство покупателям в любой точке и на любом устройстве.</p>
                <p className="black-50">Разработка страниц интернет-магазина, которые содержат подробную информацию о товаре, включая размеры, состав и уход, и убедительные фотографии, показывающие белье в реальном использовании, корзины и оплаты, чтобы сделать процесс покупки простым и безопасным, включая опции доставки и возврата товара.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/vi/logo.avif" alt="" />
                        <div className="black-50 caption-2">Новый логотип бренда.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/vi/mainpage.avif" alt="" />
                        <div className="black-50 caption-2">Главная страница интернет-магазина.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Главная страница</h4>
                <p className="black-50 mb-16">Пожелание клиента было выполнение в стиле минимализма, простой и интуитивно понятный дизайн, который позволит посетителям быстро найти нужный товар.</p>
                <p className="black-50">Далее на странице показаны популярные категории и комплекты нижнего белья. Для этого использованы фотографии с живых фотосессий. Помимо этого, добавили блоки с последними поступлениями и новыми коллекциями белья.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/vi/mainpage2.avif" alt="" />
                        <div className="black-50 caption-2">Топ категории по продажам и новинки нижнего белья.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/vi/mainpage3.avif" alt="" />
                        <div className="black-50 caption-2">Показать комплекты и разбиение на отдельные товары.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Карточка товара</h4>
                <p className="black-50">Слева отображаются фотография товара, чтобы клиенты могли более детально рассмотреть дизайн и качество изделия. Справа размещено название товара и краткое описание, размер, цвет, отзывы чтобы клиенты могли быстро определить, соответствует ли этот товар их потребностям.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/vi/card.avif" alt="" />
                        <div className="black-50 caption-2">Карточка товара.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/vi/category.avif" alt="" />
                        <div className="black-50 caption-2">Выбранная категория и карточки товаров.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Адаптивность</h4>
                <p className="black-50">52% заходов с мобильных устройств на предыдущий сайт бренда. Важно было скомбинировать решения веб-адаптивностью и мобильного приложения.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/vi/adaptive1.avif" alt="" />
                        <div className="black-50 caption-2">Главная страница и меню.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/vi/adaptive2.avif" alt="" />
                        <div className="black-50 caption-2">Пустые состояния на успешную оплату и 404 ошибку.</div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
     );
}

export default VictoriaIvankovich;