import "./Footer.scss";
import Contacts from "../Contacts/Contacts";

function Footer() {
    return ( 
        <div className="footer-page">
            <div className="contacts-info">
                <div className="telegram">
                    <h2 className="gray-color">@waldwhite</h2>
                    <h2>Для оперативной связи. Стоимость и детали совместной&nbsp;работы.</h2>
                </div>
                <div className="email">
                    <h2 className="gray-color">info@waldwhite.ru</h2>
                    <h2>Общение, обсуждение и&nbsp;передача документов.</h2>
                </div>
            </div>
            <div className="copyright-contacts">
                <div className="caption-1 gray-color">© Владимир Белов. 2023</div>
                <Contacts/>
            </div>
        </div>
     );
}

export default Footer;