import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import FraudProtection from './pages/FraudProtection/FraudProtection';
import Competenzlab from './pages/CompetenzLab/Competenzlab';
import CompetenzlabWeb from './pages/CompetenzLabWeb/CompetenzlabWeb';
import VictoriaIvankovich from './pages/VictoriaIvankovich/VictoriaIvankovich';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/projects/fraudprotection" element={<FraudProtection/>} />
          <Route path="/projects/competenzlab" element={<Competenzlab/>} />
          <Route path="/projects/victoriaivankovich" element={<VictoriaIvankovich/>} />
          <Route path="/projects/competenzlabweb" element={<CompetenzlabWeb/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
