import "./CompetenzlabWeb.scss";
import Footer from "../../components/Footer/Footer";

function CompetenzlabWeb() {
    return ( 
        <div className="page">
            <a href="/">Назад</a>

            <div className="about-case">
                <h3>CompetenzLab</h3>
                    <div className="description">
                        <p className="black-50">Технологичный стартап, который помогает переосмыслить путь развития своей карьеры, повышение квалификацию и улучшение навыков.</p>
                        <div className="expertise gap-flex">
                            <h5 className="black-50">Экспертиза</h5>
                            <div className="caption-3">Веб</div>
                            <div className="caption-3">Концепция</div>
                            <div className="caption-3">UI-Kit</div>
                        </div>
                        <div className="year-project gap-flex">
                            <h5 className="black-50">Год проекта</h5>
                            <div className="caption-3">июнь, 2021</div>
                        </div>
                        <div className="platform gap-flex">
                            <h5 className="black-50">Платформа</h5>
                            <div className="caption-3">Веб</div>
                        </div>
                    </div>
            </div>

            <div className="video-case">
                <video autoPlay muted video loop playsInline webkit-playinginline poster="../img/clweb/cl-web-cover@2x.png" >
                    <source type="video/mp4"/>
                </video>
            </div>

            <div className="container-title">
                <h4>Задача</h4>
                <p className="black-50">Спроектировать сайт, который объясняет как сервис может помочь в карьере, визуально легко и эмоционально.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/clweb/clweb-header.avif" alt="" />
                        <div className="black-50 caption-2">Шапка главной страницы.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/clweb/clweb-skillset.avif" alt="" />
                        <div className="black-50 caption-2">Управление навыками в интерфейсе.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Найм наставников</h4>
                <p className="black-50">Раздел «Найм наставников», в котором отмечены требования для будущего наставника, как происходит взаимодействие с клиентом и монетизация труда.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/clweb/clweb-coachheader.avif" alt="" />
                        <div className="black-50 caption-2">Шапка главной страницы для найма наставников.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/clweb/clweb-coachskillset.avif" alt="" />
                        <div className="black-50 caption-2">Функционал для наставников.</div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
     );
}

export default CompetenzlabWeb;