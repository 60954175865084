import "./Projects.scss";
import Case from "../Case/Case";

function Projects({ limit }) {
  const projects = [
    {
      id: 1,
      name: "Secure Note",
      description: "Сервис для передачи зашифрованных записок.",
      colorText: "white",
      colorName: "white-50",
      tag: "Веб-интерфейс",
      linkPage: "https://securenote.pro",
      urlVideo: "",
      poster: "/video/poster/sn.webp",
      newTab: "_blank",
      linkDisable: "",
    },

    {
      id: 2,
      name: "WhiteRock",
      description: "Корпоративный сайт бизнеса по разработке и поддержке ПО.",
      colorText: "black",
      colorName: "black-50",
      tag: "Скоро",
      linkPage: "",
      urlVideo: "/video/video_bg.mp4",
      poster: "",
      newTab: "",
      linkDisable: "linkDisable",
    },

    {
      id: 3,
      name: "Fraud Protection",
      description: "Сервис для передачи зашифрованных записок.",
      colorText: "white",
      colorName: "white-50",
      tag: "Веб-интерфейс",
      linkPage: "/projects/fraudprotection",
      urlVideo: "/video/fhp.mp4",
      poster: "",
      newTab: "",
      linkDisable: "",
    },

    {
      id: 4,
      name: "App X",
      description: "Находится в разработке и под NDA.",
      colorText: "black",
      colorName: "black-50",
      tag: "Скоро",
      linkPage: "",
      urlVideo: "/video/app.mp4",
      poster: "",
      newTab: "",
      linkDisable: "linkDisable",
    },

    {
      id: 5,
      name: "Trebuchet",
      description:
        "Инструмент оценки безопасности для\u00A0тестирования защиты электронной почты и\u00A0сети от целенаправленных атак.",
      colorText: "white",
      colorName: "white-50",
      tag: "Веб-интерфейс",
      linkPage: "https://trebuchet.gibthf.com/",
      urlVideo: "",
      poster: "/video/poster/trebuchet.jpg",
      newTab: "_blank",
      linkDisable: "",
    },

    {
      id: 6,
      name: "CompetenzLab",
      description:
        "Платформа для комплексного развития навыков и поиска работы.",
      colorText: "black",
      colorName: "black-50",
      tag: "Веб-интерфейс",
      linkPage: "/projects/competenzlab",
      urlVideo: "/video/cl-interface.mp4",
      poster: "",
      newTab: "",
      linkDisable: "",
    },

    {
      id: 7,
      name: "Victoria Ivankovich",
      description: "Бренд женского нижнего белья.",
      colorText: "white",
      colorName: "white-50",
      tag: "Интернет-магазин",
      linkPage: "/projects/victoriaivankovich",
      urlVideo: "/video/vi.mp4",
      poster: "",
      newTab: "",
      linkDisable: "",
    },

    {
      id: 8,
      name: "Group-IB Corporate",
      description: "Концепция главной страницы сайта компании.s",
      colorText: "white",
      colorName: "white-50",
      tag: "Веб-сайт",
      linkPage: "https://groupib-concept.framer.website/",
      urlVideo: "/video/gib-corp.mp4",
      poster: "",
      newTab: "_blank",
      linkDisable: "",
    },

    {
      id: 9,
      name: "CyberCrimeCon/19",
      description: "Промо-сайт для международной конференции по ИБ.",
      colorText: "white",
      colorName: "white-50",
      tag: "Веб-сайт",
      linkPage: "https://cybercrimecon19.framer.website/",
      urlVideo: "/video/ccc19.mp4",
      poster: "",
      newTab: "_blank",
      linkDisable: "",
    },

    {
      id: 10,
      name: "Group-IB Store",
      description: "Корпоративный интернет-магазин по продаже мерча Group-IB.",
      colorText: "white",
      colorName: "white-50",
      tag: "Веб-сайт",
      linkPage: "",
      urlVideo: "",
      poster: "/video/poster/gib-store.webp",
      newTab: "",
      linkDisable: "",
    },
  ];

  const displayedProjects = projects.slice(0, limit);

  return (
    <div className="grid-projects">
      {displayedProjects.map((project) => (
        <Case
          {...project}
          key={project.id}
          title={project.name}
          variant={project.variant}
          tag={project.tag}
          icon={project.icon}
          linkPage={project.linkPage}
          newTab={project.newTab}
          poster={project.poster}
        />
      ))}
    </div>
  );
}

export default Projects;
