import "./FraudProtection.scss";
import Footer from "../../components/Footer/Footer";

function FraudProtection() {
    return ( 
        <div className="page">
            <a href="/">Назад</a>

            <div className="about-case">
                <h3>Fraud Protection</h3>
        
                    <div className="description">
                        <p className="black-50 grid-column">Защита бизнеса и клиентов от всех видов цифровых рисков, предотвращение мошенничества в&nbsp;режиме реального времени и защита цифровой личности пользователя.</p>
                        <div className="caption-1">Изображения интерфейса взяты с официального сайта. В&nbsp;настоящий момент работа ведётся под NDA и&nbsp;запрещено выкладывать другие материалы.</div>
                        <div className="expertise gap-flex">
                            <h5 className="black-50">Экспертиза</h5>
                            <div className="caption-3">Продуктовый дизайн</div>
                            <div className="caption-3">UX Writing</div>
                            <div className="caption-3">Сбор и изучение обратной связи</div>
                            <div className="caption-3">Поддержка реализации интерфейса</div>
                        </div>
                        <div className="year-project gap-flex">
                            <h5 className="black-50">Год проекта</h5>
                            <div className="caption-3">февраль, 2021 – март, 2023</div>
                        </div>
                        <div className="platform gap-flex">
                            <h5 className="black-50">Платформа</h5>
                            <div className="caption-3">Веб</div>
                        </div>
                    </div>
            </div>

            <div className="video-case">
                <video autoPlay muted video loop playsInline webkit-playinginline >
                    <source src="../video/fhp.webm" type="video/webm"/>
                </video>
            </div>

            <div className="container-title">
                <h4>Задача</h4>
                <p className="black-50">Необходимо было изучить аналитику, выявить типы онлайн-мошенничества, с которыми нужно бороться. Затем, провёл исследования целевой аудитории и понять, каким образом они могут стать жертвами мошенничества. После этого, спроектировать интерфейс, который будет простым в использовании и удобным для конечного пользователя. Кроме&nbsp;того, необходимо было постоянно тестировать и улучшать продукт на&nbsp;основе обратной связи пользователей и изменений в трендах мошенничества.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/fp/session.avif" alt="" />
                        <div className="black-50 caption-2">Детектирование сессии с высоким уровнем риска.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/fp/graph.avif" alt="" />
                        <div className="black-50 caption-2">Графовый анализ данных связь между пользователями и устройства и аналитические отчёты.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Целевая аудитория</h4>
                <p className="black-50">Целевая аудитория включает в себя пресейлы, фрод-аналитиков и клиенты. Каждая группа имеет свои потребности, интересы и&nbsp;характеристики, которые необходимо учитывать при разработке продукта. Например, для пресейлов может быть важно наличие детальной технической информации, а для клиентов - простота использования и&nbsp;эффективность продукта в решении задач по кибербезопасности. Постоянные обсуждения помогают определить основные потребности и&nbsp;ожидания каждой группы пользователей и создать продукт, который будет наиболее полезен и привлекательный для них.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/fp/bots.avif" alt="" />
                        <div className="black-50 caption-2">Анализ и выявление всех типов бот-атак.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/fp/rules.avif" alt="" />
                        <div className="black-50 caption-2">Создание правил для управления выдачей данных.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Результат</h4>
                <p className="black-50">Результатом работы является создание функционального интерфейса, позволяющего пользователям быстро и точно выявлять мошеннические схемы и принимать меры для их предотвращения. В процессе работы над&nbsp;продуктом были определены и учтены особенности целевой аудитории, проведены анализ конкурентов и тщательный анализ рынка, что позволило разработать продукт, который соответствует требованиям пользователей и&nbsp;рынка.</p>
            </div>

            <Footer/>
        </div>
     );
}

export default FraudProtection;