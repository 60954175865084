import "./Competenzlab.scss";
import Footer from "../../components/Footer/Footer";

function Competenzlab() {
    return ( 
        <div className="page">
            <a href="/">Назад</a>

            <div className="about-case">
                <h3>CompetenzLab</h3>
                    <div className="description">
                        <p className="black-50 w-600">Инновационная онлайн-платформа, которая помогает людям изменить свою карьеру, повысить квалификацию и улучшить свои навыки.</p>
                        <div className="expertise gap-flex">
                            <h5 className="black-50">Экспертиза</h5>
                            <div className="caption-3">Продуктовый дизайн</div>
                            <div className="caption-3">UX Writing</div>
                            <div className="caption-3">Исследования и аналитика</div>
                            <div className="caption-3">Дизайн-система</div>
                        </div>
                        <div className="year-project gap-flex">
                            <h5 className="black-50">Год проекта</h5>
                            <div className="caption-3">март — июнь, 2021</div>
                        </div>
                        <div className="platform gap-flex">
                            <h5 className="black-50">Платформа</h5>
                            <div className="caption-3">Веб</div>
                        </div>
                    </div>
            </div>

            <div className="video-case">
                <video autoPlay muted video loop playsInline webkit-playinginline >
                    <source src="../video/cl-interface.webm" type="video/webm"/>
                </video>
            </div>

            <div className="container-title">
                <h4>Задача</h4>
                <p className="black-50 mb-16">Первый этап - изучение конкурентов. Это включало в себя анализ конкурентов на рынке, изучение их продуктов, сравнение их особенностей и возможностей, а также выявление их преимуществ и недостатков. Это помогло определить лучшие практики и создать инновационный продукт.</p>
                <p className="black-50 mb-16">Следующий этап - проектирование UI-Kit. На этом этапе создаются компоненты, которые позволили мне и разработчиком легко использовать проектировать и передавать в разработку.</p>
                <p className="black-50">Далее необходимо построить сценарии использования платформы. Это помогло определить, как пользователи будут использовать платформу и какие задачи они будут выполнять. На этом этапе уделили внимание удобству использования и эффективности выполнения задач.</p>
                
                <div className="images">
                    <div className="case-img">
                        <img src="../img/cl/oldcon.avif" alt="" />
                        <div className="black-50 caption-2">Первый черновой вариант отображения главной страницы.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/cl/fincon.avif" alt="" />
                        <div className="black-50 caption-2">Итоговый вариант главной страницы.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Клиентский интерфейс</h4>
                <p className="black-50">Пользователь может просмотреть свой план и изучить его содержание. Он также может ознакомиться с анализом своих слабых и сильных сторон, чтобы понять, какие навыки нужно развивать. Пользователь может изучить возможности в выбранной сфере и определить, куда двигаться по карьерной лестнице. Он может искать курсы и литературу, рекомендованные наставником, и следовать своему плану, чтобы достичь своих целей. Все эти функции позволяют клиентам платформы повышать свою квалификацию и развиваться в своей карьере.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/cl/roadmap.avif" alt="" />
                        <div className="black-50 caption-2">План развития через дорожную карту, которую строит наставник.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/cl/goals.avif" alt="" />
                        <div className="black-50 caption-2">Поставленные цели на определенный срок и с приоритетом от наставника.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Карьерный путь</h4>
                <p className="black-50">Пользователю представлены инструменты и информация для планирования своей карьеры. Поэтому платформа позволяет посмотреть весь карьерный путь в выбранной профессии: от&nbsp;зарплаты до требуемых навыков для изучения.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/cl/careerlist.avif" alt="" />
                        <div className="black-50 caption-2">Основные профессии, выбранные пользователем в виде списка.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/cl/careergraph.avif" alt="" />
                        <div className="black-50 caption-2">Пути и развитие выбранных профессий в виде графа. На макете построена простая схема.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Поиск вакансий</h4>
                <p className="black-50">Платформа позволяет отслеживать открытые вакансии в любой стране, фильтруя по сферам, по&nbsp;компаниям, изучать отзывы и анализировать заработную плату.</p>
                <div className="images">
                    <div className="case-img">
                        <img src="../img/cl/vacancy.avif" alt="" />
                        <div className="black-50 caption-2">Вакансии по выбранным параметрами.</div>
                    </div>
                    <div className="case-img">
                        <img src="../img/cl/aboutcompany.avif" alt="" />
                        <div className="black-50 caption-2">Детали компании и открытые вакансии в ней.</div>
                    </div>
                </div>
            </div>

            <div className="container-title">
                <h4>Результат</h4>
                <p className="black-50">В результате работы по проектированию интерфейса данной платформы были разработаны удобные и интуитивно понятные макеты, которые удовлетворяют потребности пользователей и&nbsp;обеспечивают легкий доступ к основным функциям. </p>
            </div>

            <Footer/>
        </div>
     );
}

export default Competenzlab;