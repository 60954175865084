import "./Case.scss";
import Tag from "../Tag/Tag";

function Case({
  tag,
  colorText,
  colorName,
  title,
  description,
  poster,
  urlVideo,
  linkPage,
  linkDisable,
  newTab,
}) {
  return (
    <div className="case-project">
      <a
        className={linkDisable}
        href={linkPage}
        rel="norefferer"
        target={newTab}
      >
        <div className="description-case">
          <Tag label={tag}></Tag>
          <div className="info-case">
            <h5 className={colorText}>
              <span className={colorName}>{title}.</span>&nbsp;{description}
            </h5>
          </div>
        </div>
        <video
          autoPlay
          muted
          video
          loop
          playsInline
          webkit-playinginline
          poster={poster}
        >
          <source src={urlVideo} type="video/mp4" />
        </video>
      </a>
    </div>
  );
}

export default Case;
