import "./Contacts.scss";

function Contacts () {
    return (
        <div className="contacts">
            <a href="/#">Email</a>
            <a href="/#">Behance</a>
            <a href="/#">Instagram</a>
            <a href="/#">Telegram</a>
        </div>
    );
}

export default Contacts;